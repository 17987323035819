import manageData from "../shared/manage_data";
import {AjaxSync} from "../shared/ajax_utilities";
import {modalGeneric} from "../shared/common";

$(function(){

    app.DOM.filter = app.DOM.content.find('#filter');

    const $econsent_btn = app.DOM.content.find('.tab-eligible .btn-submit-form');
    const $econsent_btn_all = app.DOM.content.find('.eligible-all');
    const $eligible_inputs = app.DOM.content.find('#mass_econsent input').not('.eligible-all');
    const $eligible_trs = app.DOM.content.find('#mass_econsent tr');
    const $ineligible_trs = app.DOM.content.find('#in-eligible tr');
    const $ineligible_inputs = app.DOM.form_input.not('.eligible');

    function setupDatepickers()
    {
        // do nothing
        if( app.DOM.form_input.length === 0 ){
            return;
        }

        // datepicker
        app.DOM.form_input.filter('.datepicker').datepicker({
            changeMonth: true,
            changeYear: true,
            yearRange: "-90:-18"
        });
    }

    function setupInputs()
    {
        // show submit
        app.DOM.form_input.filter('button').css('display', 'inline-block');
        app.DOM.header_btn_save.css('display', 'block');

        // check empty
        manageData.highlightEmptyInputs($ineligible_inputs, $ineligible_trs);
    }

    $eligible_inputs.on('change', e => {

        let checkedCount = 0;
        $.each($eligible_inputs, (k, el) => {
            if($(el).is(':checked')) {
                checkedCount++;
                $econsent_btn.addClass('button-primary').removeClass('button-grey');
            }
        });

        if( !$eligible_inputs.filter(':checked').length ) {
            $econsent_btn.removeClass('button-primary').addClass('button-grey');
        }
    });

    $econsent_btn_all.on('change', e => {
        const el = $(e.currentTarget);
        const $tableInputs = el.closest('table').find('tbody :input');
        const $tableSubmit = el.closest('table').prev().find('.btn-submit-form');

        if(el.is(':checked')) {
            $tableInputs.prop('checked', true);
            $tableSubmit.addClass('button-primary').removeClass('button-grey');
            return;
        }

        $tableSubmit.addClass('button-grey').removeClass('button-primary');
        $tableInputs.prop('checked', false);
    });

    $econsent_btn.on('click', e => {
        const $btn = $(e.currentTarget);
        const data = { e_consent: [] };

        // do nothing
        if( $btn.hasClass('button-grey') || $btn.hasClass('ajax') ) {
            return;
        }

        // confirmation
        if( !confirm(`Are you sure you want to send an e-declaration to ${$eligible_inputs.filter(':checked').length} driver(s)`) ) {
            return;
        }

        $eligible_inputs.each(function(k, el){
            el = $(el);
            if(el.is(':checked')) {
                data.e_consent.push(el.val());
            }
        });

        $btn.addClass('ajax');

        AjaxSync({
            method: 'POST',
            url: `${app.CACHE.URL_AJAX_DRIVERLOOKUP}consent_mass`,
            data: data
        }, {
            done: (res) => {
                if( res.data.success && res.data.failed ) {
                    const msgSuccess = ( Object.keys(res.data.success).length ) ? `<p class="b green">You have successfully sent E-Declarations to ${Object.keys(res.data.success).length} driver(s), please allow up to 5 minutes for the driver to receive the E-Declaration email</p>` : '';
                    let msgError = ( Object.keys(res.data.failed).length ) ? `<p><span class="red b">There were error(s) sending E-Declarations to the following driver(s)</span>` : '';

                    if( msgError ) {
                        $.each(res.data.failed, (k,v) => {
                            msgError += `<br><span class="b">${v.name}:</span>`;
                            $.each(v.errors, (kk, vv) => {
                                msgError += `<br>- ${vv}<br>`;
                            });
                        });

                        // remove last br
                        msgError = `${msgError.slice(0, -4)}</p>`;
                    }

                    modalGeneric('Licence Check E-Declarations', msgSuccess+msgError);

                // update timestamps&count
                $.each(res.data.success, function(id, count){
                    const $tr = $eligible_trs.filter(`[data="${id}"]`);
                    $tr.find('.driver_lookup_consent_email').text('Queued');
                    $tr.find('.driver_lookup_consent_emails_sent').text(count);
                });
            }

                $btn.removeClass('ajax button-primary').addClass('button-grey');
                $eligible_inputs.prop('checked', false);
                $econsent_btn_all.prop('checked', false);
            }
        });
    });

    // set min/max lengths for inputs based on region
    manageData.licenceNumberRegion(app.DOM.content.find('#in-eligible [data-field="licence_region"]'));

    window.addEventListener('tab-section-change', (e) => {
        if(e.detail.tab === 'eligible') {
            return;
        }
        app.DOM.header_btn_save.show();
    });

    setupDatepickers();

    setupInputs();

    app.multiSelect();
});